/*
 * @Author: your name
 * @Date: 2021-08-31 15:23:40
 * @LastEditTime: 2021-09-26 16:33:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \cloud_classroom\src\utils\api_host.js
 */

// const demand = 'ws://192.168.0.101:8088/vod/'
// 本地环境
// const baseURL = "http://192.168.0.123:8085/cloudClassroom/app/"; //  云课堂 内网测试与打包地址
// const baseURL = "http://www.zhijijob.com/cloudClassroom/app/api/app"; // 职技网 接口请求链接
// const baseURL = "http://192.168.0.117:8085/cloudClassroom/app"; // 接口请求链接
// const demand = 'ws://192.168.0.10:8085/vod/'   // 视频点播连接的websoket链接
// const chatURL = 'ws://192.168.0.10:8085/chat/' // 聊天室链接

// 公司服务器
// const baseURL ='http://117.78.11.175/cloudClassroom/app/api/app/'
// const chatURL = 'ws://117.78.11.175/cloudClassroom/ws/chat/'
// const demand = 'ws://117.78.11.175/cloudClassroom/ws/chat/vod/'

// 粤务通
// const baseURL = 'https://www.zhijiao168.com/cloudClassroom/app/api/app'
// const chatURL = 'wss://www.zhijiao168.com/cloudClassroom/ws/chat/'
// const demand = 'wss://www.zhijiao168.com/cloudClassroom/ws/vod/'

const baseURL = "https://www.zhijiao168.com/cloudClassroom/app/api/app";
const chatURL = "wss://ws.zhijiao168.com/cloudClassroom/ws/chat/";
const demand = "wss://www.zhijiao168.com/cloudClassroom/ws/vod/";

// 粤务通测试
// const baseURL = 'https://www.zhijiao168.com/test/cloudClassroom/app/api/app'
// const chatURL = 'wss://www.zhijiao168.com/test/cloudClassroom/ws/chat/'
// const demand = 'wss://www.zhijiao168.com/test/cloudClassroom/ws/vod/'

export default { baseURL, chatURL, demand };
